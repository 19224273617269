import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from "../components/layout"
import styled from 'styled-components'
import Seo from "../components/seo"
import Banner from "../components/banner"

class PortfolioItemTemplate extends React.Component {
  render(data) {

  const portfolioItem = get(this.props, 'data.nodePortfolioItem');
  const portfolioImages = get(this.props, 'data.nodePortfolioItem.relationships.field_images');
  const portfolioLink = get(this.props, 'data.nodePortfolioItem.field_link.uri');

    return (
      <Layout>
      <Seo title={portfolioItem.title} />

       <Banner title={portfolioItem.title} />

      	<MainWrapper>

	        <Wrapper>

            {
              portfolioItem.body.map(body => (
                <div className="body" dangerouslySetInnerHTML={{ __html: body.processed }} />
              ))
            }

            {
              portfolioImages.map(image => (
                <img loading="lazy" src={image.localFile.childImageSharp.fluid.src} alt={portfolioItem.title} />
              ))
            }

	       </Wrapper>


         {portfolioLink && 


           <LinkWrapper>
              <a href={portfolioLink} target="_blank" rel="noreferrer" className="website-link">Visit website</a>
            </LinkWrapper>

         }

       
	    </MainWrapper>     

      </Layout>
    ) 
  }
}

export default PortfolioItemTemplate

export const PageQuery = graphql`
  query PageBySlug($portfolioItemId: String!) {
    site {
      siteMetadata {
        title
      }
    }

    nodePortfolioItem(id: { eq: $portfolioItemId }) {
	    body {
	      value
	      processed
	    }
	    created(formatString: "dddd Do, MMMM YYYY")
	    title
	    id
      field_link {
        uri
      }
      relationships {
        field_images {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
  	 }

    allFileFile {
      edges {
        node {
          drupal_id
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

   }
 `

 const MainWrapper = styled.div`
  
  }
 `

 const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 40px auto;

    .website-link {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 2px;
      border: 1px solid;
      padding: 10px 20px;
      font-size: 20px;

      &:hover {
        background: white;
        color: #272f32;
      }
    }
 `

 const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div.body,
    img  {
      display: flex;

      @media(min-width: 750px) {
        width: 50%;
      }
    }

    div.body {
      align-self: center;
      color: #fff;
      padding: 40px;
      flex-direction: column;

      h4 {
        text-transform: uppercase;
        font-size: 22px;
        letter-spacing: 2px;
      }

      p {
        margin-bottom: 0;
      }
    }

    div.body:nth-of-type(1) {
      order: 1;
    }

    img:nth-of-type(1) {
      order: 2;
    }

    div.body:nth-of-type(2) {
      order: 3;
    }

    img:nth-of-type(2) {
      order: 4;
    }

    div.body:nth-of-type(3) {
      order: 5;
    }

    img:nth-of-type(3) {
      order: 6;
    }     

    /* Desktop */
    @media(min-width: 750px) {

      img:nth-of-type(1) {
        order: 1;
      }

      div.body:nth-of-type(1) {
        order: 2;
      }

      div.body:nth-of-type(2) {
        order: 3;
      }

      img:nth-of-type(2) {
        order: 4;
      }

      img:nth-of-type(3) {
        order: 5;
      }

      div.body:nth-of-type(3) {
        order: 6;
      }
    }
 `